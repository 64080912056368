import React, { useRef } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import style from './PeopleList.module.scss';
import { SmartLink } from '../SmartLink';
import CarouselStandard from '../CarouselStandard';

const PeopleList = props => {
    const { title, cta, items, compTypes, disclaimerText } = props;
    if (items.length <= 0) {
        return <></>;
    }

    const sliderBreakpoints = [
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1360,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 599,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 460,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        }
    ];

    const sliderParams = {
        slidesToShow: 1,
        slidesToScroll: 4,
        arrows: false,
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: false,
        responsive: sliderBreakpoints,
    };

    const imageRefs = useRef([]);
    const imageLoaded = (name) => {
        const ref = imageRefs.current.find(ref => ref.name === name);
        if (ref) ref.element.classList?.add(style.loaded);
    }

    return (
        <div
            className={[
                style.el,
                'people-list',
                title.toLowerCase() === 'related team' || compTypes?.includes('portfolioBasicInfo')
                    ? style.diffLayout
                    : '',
                cta?.link?.url ? '' : style.noBorder,
            ].join(' ')}
        >
            <CarouselStandard className={style.carouselOverrides} title={title} secondaryText={disclaimerText} cta={cta} sliderBreakpoints={sliderBreakpoints} sliderParams={sliderParams}>
                {items.map(({ image, name, link }, id) => (
                    <div key={`item${id}`} className={style.outerImageWrap} ref={(element) => imageRefs.current.push({ name: name, element: element })}>
                        <SmartLink href={link.url} className={style.peopleImg} aria-label={name}>
                            <div className={style.imageWrap}>
                                <div className={style.image}>
                                    <Image priority={true} src={image && image.url ? image.url : '/images/default.png'} alt="Team Member" layout="fill" onLoadingComplete={() => imageLoaded(name)} />
                                </div>
                            </div>
                            <div className={style.peopleName}>{name}</div>
                        </SmartLink>
                    </div>
                ))}
            </CarouselStandard>
        </div>
    );
};

PeopleList.propTypes = {
    cta: PropTypes.object,
    title: PropTypes.string,
    disclaimerText: PropTypes.string,
    items: PropTypes.array,
    type: PropTypes.string,
    compTypes: PropTypes.array,
};
export default PeopleList;
PeopleList.defaultProps = {};
