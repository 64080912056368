import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { trackGaEvent } from '../../utils/gtm';

import style from './QuoteCarousel.module.scss';

const QuoteCarousel = ({ quotes, currentUrl, headline }) => {
    const sliderEl = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const st = window.__sharethis__;
            if (typeof st !== 'undefined') {
                st.loader['custom-share-buttons']();
            }
        }
    }, []);

    const sliderParams = {
        slidesToShow: 1,
        arrows: true,
        prevArrow: (
            <button className={style.prevBtn} ref={prevRef}>
                <span className='bar'></span>
                <span className='sr-only'>previous</span>
            </button>
        ),
        nextArrow: (
            <button className={style.nextBtn} ref={nextRef}>
                <span className='bar'></span>
                <span className='sr-only'>next</span>
            </button>
        ),
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: true,
    };

    return (
        <div className={style.el}>
            <div className={style.sliderWrapper}>
                <Slider {...sliderParams} ref={sliderEl}>
                    {quotes &&
                        quotes?.map((item, idx) => (
                            <div className={style.quoteWrapper} key={idx}>
                                <div className={style.quote} key={idx}>
                                    <div className={style['quote-title']}>{item?.quote}</div>
                                    <div className={style.quoteRow}>
                                        <div className={style.quoteAuthor}>
                                            {item?.authorLink?.link && (
                                                <a
                                                    className={style.quoteAuthorDetails}
                                                    href={item?.authorLink?.link.url}
                                                >
                                                    {item?.quoteImage?.url && (
                                                        <div className={style.quoteAuthorImageWrapper}>
                                                            <img
                                                                className={style.quoteAuthorImage}
                                                                src={item?.quoteImage.url}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className={style['quote-author']}>
                                                        <div className={style.quoteAuthorName}>{item?.authorName}</div>
                                                        <div className={style.quoteAuthorRole}>{item?.authorRole}</div>
                                                    </div>
                                                </a>
                                            )}
                                            {!item?.authorLink?.link && (
                                                <div className={style.quoteAuthorDetails}>
                                                    {item?.quoteImage?.url && (
                                                        <div className={style.quoteAuthorImageWrapper}>
                                                            <img
                                                                className={style.quoteAuthorImage}
                                                                src={item?.quoteImage.url}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className={style['quote-author']}>
                                                        <div className={style.quoteAuthorName}>{item?.authorName}</div>
                                                        <div className={style.quoteAuthorRole}>{item?.authorRole}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={style.quoteShare}>
                                            <div
                                                key={idx}
                                                className={[style.quoteLinkedIn, 'st-custom-button'].join(' ')}
                                                data-network='linkedin'
                                                data-url={currentUrl}
                                                data-title={headline}
                                                data-image='/images/image.jpg'
                                                onClick={trackGaEvent('social_icon_click', 'linkedin', currentUrl)}
                                            >
                                                <svg
                                                    className={style.linkedInIcon}
                                                    width='22'
                                                    height='20'
                                                    viewBox='0 0 22 20'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        d='M4.99327 19.999V6.50506H0.277323V19.999H4.99327ZM2.6359 4.66158C4.28044 4.66158 5.30408 3.6254 5.30408 2.33052C5.27344 1.00644 4.2805 -0.000976562 2.66711 -0.000976562C1.05398 -0.000976562 -0.000915527 1.00646 -0.000915527 2.33052C-0.000915527 3.62546 1.02247 4.66158 2.60512 4.66158H2.63576H2.6359ZM7.60353 19.999H12.3195V12.4633C12.3195 12.06 12.3501 11.6572 12.4746 11.3689C12.8156 10.5631 13.5915 9.72852 14.8943 9.72852C16.6008 9.72852 17.2835 10.966 17.2835 12.78V19.9989H21.9991V12.2616C21.9991 8.1168 19.6725 6.18826 16.5697 6.18826C14.0257 6.18826 12.9086 7.5407 12.2881 8.46184H12.3196V6.50478H7.60363C7.66552 7.77098 7.60363 19.9988 7.60363 19.9988L7.60353 19.999Z'
                                                        fill='white'
                                                    />
                                                </svg>
                                                Share to Linkedin
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    );
};

QuoteCarousel.propTypes = {
    quotes: PropTypes.array,
    currentUrl: PropTypes.string,
    headline: PropTypes.string,
};
export default QuoteCarousel;

QuoteCarousel.defaultProps = {};
