import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './CaseStudyScrollHijack.module.scss';
import { SmartLink } from '../SmartLink';
import ReportCard from '../Cards/reportCard';
import { useRouter } from 'next/router';
// import { useMediaQuery } from '@react-hook/media-query';
import { removeQuery } from '../../utils/queryString';
import QuoteCarousel from '../QuoteCarousel';

const CaseStudyScrollHijack = ({ title, caseStudy, description, detail, download, icon, image, stats, quotes }) => {
    // const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    const router = useRouter();

    let link = '';

    if (caseStudy && caseStudy.link) {
        const pagePath = caseStudy.link?.url.replace(/\/$/, '');
        link = caseStudy ? `${removeQuery(router.asPath)}?pageId=${pagePath}` : caseStudy.link?.url;
    }

    const websiteLink =
        detail.website.value.indexOf('http') > -1 ? detail.website.value : 'http://' + detail.website.value;

    return (
        <>
            <div className={style.el}>
                <div className={style.innerHeader}>
                    <div className={style.content}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <div className={style.contentWrapper}>
                                <div className={style.companyGroups}>
                                    <div className={style.icon}>
                                        <img src={icon.url} alt={icon.alt} />
                                    </div>
                                    <div className={style.companyName}>{title}</div>
                                </div>

                                <div
                                    className={style.companyDescription}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                                <div className={style.companyDetails}>
                                    <div className={style.headingText}>{detail.title}</div>
                                    {detail.sector?.value && (
                                        <div className={style.companyDetailsRow}>
                                            <img src='/images/casestudy/ico.sector.svg' alt='' />
                                            <p>{detail.sector.title}</p>
                                            <p>{detail.sector.value}</p>
                                        </div>
                                    )}
                                    {detail.countryRegion?.countries.length > 0 && (
                                        <div className={style.companyDetailsRow}>
                                            <img src='/images/casestudy/ico.countryregion.svg' alt='' />
                                            <p>{detail.countryRegion.title}</p>
                                            <p>
                                                {detail.countryRegion.countries.map((country, i) => (
                                                    <Fragment key={country.text}>
                                                        {country.text}
                                                        {i < detail.countryRegion.countries.length - 1 ? ', ' : ''}
                                                    </Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                    {detail.status?.value && (
                                        <div className={style.companyDetailsRow}>
                                            <img src='/images/casestudy/ico.status.svg' alt='' />
                                            <p>{detail.status.title}</p>
                                            <p>{detail.status.value}</p>
                                        </div>
                                    )}
                                    {detail.website?.value && (
                                        <div className={style.companyDetailsRow}>
                                            <img src='/images/casestudy/ico.website.svg' alt='' />
                                            <p>{detail.website.title}</p>
                                            <p>
                                                <SmartLink href={websiteLink}>{detail.website.value}</SmartLink>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {quotes && <QuoteCarousel quotes={quotes} currentUrl={websiteLink} headline={title} />}
                        </div>
                    </div>
                    <div className={style.scrollGroup}>
                        {image?.url && (
                            <div
                                className={style.image}
                                style={{
                                    backgroundImage: `url(${image.url})`,
                                }}
                            />
                        )}
                        <div className={style.fourColsInfo}>
                            <div className={[style.container, 'container'].join(' ')}>
                                <div className={style.contentStats}>
                                    <div className={style.stats}>
                                        {stats &&
                                            stats.map((stats, id) => (
                                                <div className={style.statsInfo} key={id}>
                                                    <div className={['h1', style.statsTitle].join(' ')}>
                                                        {stats.title}
                                                    </div>
                                                    <div
                                                        className={style.statsDesc}
                                                        dangerouslySetInnerHTML={{
                                                            __html: stats.description,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                    {download?.cta?.link && <ReportCard {...download} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {caseStudy?.link && (
                <div className={style.meetCta}>
                    <div className={style.ctaLink}>
                        <SmartLink href={link} hasArrow className='arrow-link'>
                            {caseStudy.text}
                        </SmartLink>
                    </div>
                </div>
            )}
        </>
    );
};
CaseStudyScrollHijack.propTypes = {
    title: PropTypes.string,
    caseStudy: PropTypes.object,
    description: PropTypes.string,
    detail: PropTypes.object,
    download: PropTypes.object,
    icon: PropTypes.object,
    image: PropTypes.object,
    stats: PropTypes.array,
    quotes: PropTypes.array,
};
export default CaseStudyScrollHijack;
